import React from 'react';

export const Europe = {
    "name": "Europe",
    "link": "europe",

    "thumb": "https://live.staticflickr.com/65535/50379845643_f0ea83cd2b_t.jpg",

    "images": [
        "https://live.staticflickr.com/65535/50379845643_a72b40bfa6_o.jpg",
        "https://cdn.pixabay.com/photo/2015/11/27/20/28/arno-river-1066307_1280.jpg",
        "https://images.unsplash.com/photo-1542379950-b3fc716c16f1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1280",
        "https://cdn.pixabay.com/photo/2019/07/16/20/48/dolomites-4342572_1280.jpg",
        "https://images.unsplash.com/photo-1547448415-e9f5b28e570d?ixlib=rb-1.2.1&w=1280",
        "https://cdn.pixabay.com/photo/2020/05/24/13/29/dubrovnik-5214286_1280.jpg"
    ],

    "description": "There is not a simple way to describe the old continent. With centuries and centuries of history, discoveries, wars and conquests, each country here has a magnifique history and a huge pack of adventures for all kind of desires. From the fairy tail castles in Germany, France or Scotland, to the amazing beaches and cities of Spain, Portugal, Italy, Croatia and Greece, or even the strong history and culture of the Nordic, you will certainly not be disappointed on your trips.",

    /*
    {
        "name": "Croatia",
        "link": "croatia",
        "image": "https://images.unsplash.com/photo-1533140246-be4787db56cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=968&q=80"
    },
    {
        "name": "Spain",
        "link": "spain",
        "image": "https://images.unsplash.com/photo-1509840841025-9088ba78a826?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1050&q=80"
    },
    */

    "destinations": [
        {
            "name": "Malta",
            "link": "malta",
            "image": "https://live.staticflickr.com/65535/50494357193_4dc67338cd_o.jpg"
        },
        {
            "name": "Scotland",
            "link": "scotland",
            "image": "https://cdn.pixabay.com/photo/2016/11/29/09/29/mountains-1868715_1280.jpg"
        },
        {
            "name": "Barcelona",
            "link": "barcelona",
            "image": "https://live.staticflickr.com/65535/50495061301_5c705e2230_o.jpg"
        },
        {
            "name": "Budapest",
            "link": "budapest",
            "image": "https://live.staticflickr.com/65535/50495076261_bec7080a3a_o.jpg"
        },
        {
            "name": "Krakow",
            "link": "krakow",
            "image": "https://live.staticflickr.com/65535/50848947006_2237e9511c.jpg"
        },
        {
            "name": "Paris",
            "link": "paris",
            "image": "https://live.staticflickr.com/65535/50494371573_6f48a53457_o.jpg"
        },
        {
            "name": "Prague",
            "link": "prague",
            "image": "https://live.staticflickr.com/65535/50495218517_3d79a75060_o.jpg"
        }
    ]
}
